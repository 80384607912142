const varietiesData = {
  categories: [
    {
      id: "all-male-hybrids",
      name: "categories.all-male-hybrids.name",
      description: "categories.all-male-hybrids.description",
      features: [
        "categories.all-male-hybrids.features.0",
        "categories.all-male-hybrids.features.1",
        "categories.all-male-hybrids.features.2",
      ],
      varieties: ["jersey-giant", "jersey-knight", "jersey-supreme", "guelph-millennium", "mondeo"],
    },
    {
      id: "purple-varieties",
      name: "categories.purple-varieties.name",
      description: "categories.purple-varieties.description",
      features: [
        "categories.purple-varieties.features.0",
        "categories.purple-varieties.features.1",
        "categories.purple-varieties.features.2",
      ],
      varieties: ["purple-passion", "viola"],
    },
    {
      id: "open-pollinated",
      name: "categories.open-pollinated.name",
      description: "categories.open-pollinated.description",
      features: [
        "categories.open-pollinated.features.0",
        "categories.open-pollinated.features.1",
        "categories.open-pollinated.features.2",
      ],
      varieties: ["mary-washington", "martha-washington", "viking-kb3"],
    },
    {
      id: "warm-climates",
      name: "categories.warm-climates.name",
      description: "categories.warm-climates.description",
      features: [
        "categories.warm-climates.features.0",
        "categories.warm-climates.features.1",
        "categories.warm-climates.features.2",
      ],
      varieties: ["apollo", "atlas", "grande"],
    },
    {
      id: "disease-resistant",
      name: "categories.disease-resistant.name",
      description: "categories.disease-resistant.description",
      features: [
        "categories.disease-resistant.features.0",
        "categories.disease-resistant.features.1",
        "categories.disease-resistant.features.2",
      ],
      varieties: ["jersey-king", "jersey-titan", "jersey-gem"],
    },
    {
      id: "european-varieties",
      name: "categories.european-varieties.name",
      description: "categories.european-varieties.description",
      features: [
        "categories.european-varieties.features.0",
        "categories.european-varieties.features.1",
        "categories.european-varieties.features.2",
      ],
      varieties: ["limbras-22", "limbras-26", "lucullus"],
    },
    {
      id: "newer-hybrids",
      name: "categories.newer-hybrids.name",
      description: "categories.newer-hybrids.description",
      features: [
        "categories.newer-hybrids.features.0",
        "categories.newer-hybrids.features.1",
        "categories.newer-hybrids.features.2",
      ],
      varieties: ["sequoia", "porthos"],
    },
    {
      id: "niche-varieties",
      name: "categories.niche-varieties.name",
      description: "categories.niche-varieties.description",
      features: [
        "categories.niche-varieties.features.0",
        "categories.niche-varieties.features.1",
        "categories.niche-varieties.features.2",
      ],
      varieties: ["jersey-queen", "idalee-f2"],
    },
  ],
  varieties: [
    {
      id: "jersey-giant",
      name: "varieties.jersey-giant.name",
      category: "all-male-hybrids",
      description: "varieties.jersey-giant.description",
      features: [
        "varieties.jersey-giant.features.0",
        "varieties.jersey-giant.features.1",
        "varieties.jersey-giant.features.2",
      ],
      images: ["/assets/img/jersey-giant-1.webp", "/assets/img/jersey-giant-2.webp"],
    },
    {
      id: "jersey-knight",
      name: "varieties.jersey-knight.name",
      category: "all-male-hybrids",
      description: "varieties.jersey-knight.description",
      features: [
        "varieties.jersey-knight.features.0",
        "varieties.jersey-knight.features.1",
        "varieties.jersey-knight.features.2",
      ],
      images: ["/assets/img/jersey-knight-1.webp", "/assets/img/jersey-knight-2.webp"],
    },
    {
      id: "jersey-supreme",
      name: "varieties.jersey-supreme.name",
      category: "all-male-hybrids",
      description: "varieties.jersey-supreme.description",
      features: [
        "varieties.jersey-supreme.features.0",
        "varieties.jersey-supreme.features.1",
        "varieties.jersey-supreme.features.2",
      ],
      images: ["/assets/img/jersey-supreme-1.webp", "/assets/img/jersey-supreme-2.webp"],
    },
    {
      id: "guelph-millennium",
      name: "varieties.guelph-millennium.name",
      category: "all-male-hybrids",
      description: "varieties.guelph-millennium.description",
      features: [
        "varieties.guelph-millennium.features.0",
        "varieties.guelph-millennium.features.1",
        "varieties.guelph-millennium.features.2",
      ],
      images: ["/assets/img/guelph-millennium-1.webp", "/assets/img/guelph-millennium-2.webp"],
    },
    {
      id: "mondeo",
      name: "varieties.mondeo.name",
      category: "all-male-hybrids",
      description: "varieties.mondeo.description",
      features: [
        "varieties.mondeo.features.0",
        "varieties.mondeo.features.1",
        "varieties.mondeo.features.2",
      ],
      images: ["/assets/img/mondeo-1.webp", "/assets/img/mondeo-2.webp"],
    },
    {
      id: "purple-passion",
      name: "varieties.purple-passion.name",
      category: "purple-varieties",
      description: "varieties.purple-passion.description",
      features: [
        "varieties.purple-passion.features.0",
        "varieties.purple-passion.features.1",
        "varieties.purple-passion.features.2",
      ],
      images: ["/assets/img/purple-passion-1.webp", "/assets/img/purple-passion-2.webp"],
    },
    {
      id: "viola",
      name: "varieties.viola.name",
      category: "purple-varieties",
      description: "varieties.viola.description",
      features: [
        "varieties.viola.features.0",
        "varieties.viola.features.1",
        "varieties.viola.features.2",
      ],
      images: ["/assets/img/viola-1.webp", "/assets/img/viola-2.webp"],
    },
    {
      id: "mary-washington",
      name: "varieties.mary-washington.name",
      category: "open-pollinated",
      description: "varieties.mary-washington.description",
      features: [
        "varieties.mary-washington.features.0",
        "varieties.mary-washington.features.1",
        "varieties.mary-washington.features.2",
      ],
      images: ["/assets/img/mary-washington-1.webp", "/assets/img/mary-washington-2.webp"],
    },
    {
      id: "martha-washington",
      name: "varieties.martha-washington.name",
      category: "open-pollinated",
      description: "varieties.martha-washington.description",
      features: [
        "varieties.martha-washington.features.0",
        "varieties.martha-washington.features.1",
        "varieties.martha-washington.features.2",
      ],
      images: ["/assets/img/martha-washington-1.webp", "/assets/img/martha-washington-2.webp"],
    },
    {
      id: "viking-kb3",
      name: "varieties.viking-kb3.name",
      category: "open-pollinated",
      description: "varieties.viking-kb3.description",
      features: [
        "varieties.viking-kb3.features.0",
        "varieties.viking-kb3.features.1",
        "varieties.viking-kb3.features.2",
      ],
      images: ["/assets/img/viking-kb3-1.webp", "/assets/img/viking-kb3-2.webp"],
    },
    {
      id: "apollo",
      name: "varieties.apollo.name",
      category: "warm-climates",
      description: "varieties.apollo.description",
      features: [
        "varieties.apollo.features.0",
        "varieties.apollo.features.1",
        "varieties.apollo.features.2",
      ],
      images: ["/assets/img/apollo-1.webp", "/assets/img/apollo-2.webp"],
    },
    {
      id: "atlas",
      name: "varieties.atlas.name",
      category: "warm-climates",
      description: "varieties.atlas.description",
      features: [
        "varieties.atlas.features.0",
        "varieties.atlas.features.1",
        "varieties.atlas.features.2",
      ],
      images: ["/assets/img/atlas-1.webp", "/assets/img/atlas-2.webp"],
    },
    {
      id: "grande",
      name: "varieties.grande.name",
      category: "warm-climates",
      description: "varieties.grande.description",
      features: [
        "varieties.grande.features.0",
        "varieties.grande.features.1",
        "varieties.grande.features.2",
      ],
      images: ["/assets/img/grande-1.webp", "/assets/img/grande-2.webp"],
    },
    {
      id: "jersey-king",
      name: "varieties.jersey-king.name",
      category: "disease-resistant",
      description: "varieties.jersey-king.description",
      features: [
        "varieties.jersey-king.features.0",
        "varieties.jersey-king.features.1",
        "varieties.jersey-king.features.2",
      ],
      images: ["/assets/img/jersey-king-1.webp", "/assets/img/jersey-king-2.webp"],
    },
    {
      id: "jersey-titan",
      name: "varieties.jersey-titan.name",
      category: "disease-resistant",
      description: "varieties.jersey-titan.description",
      features: [
        "varieties.jersey-titan.features.0",
        "varieties.jersey-titan.features.1",
        "varieties.jersey-titan.features.2",
      ],
      images: ["/assets/img/jersey-titan-1.webp", "/assets/img/jersey-titan-2.webp"],
    },
    {
      id: "jersey-gem",
      name: "varieties.jersey-gem.name",
      category: "disease-resistant",
      description: "varieties.jersey-gem.description",
      features: [
        "varieties.jersey-gem.features.0",
        "varieties.jersey-gem.features.1",
        "varieties.jersey-gem.features.2",
      ],
      images: ["/assets/img/jersey-gem-1.webp", "/assets/img/jersey-gem-2.webp"],
    },
    {
      id: "limbras-22",
      name: "varieties.limbras-22.name",
      category: "european-varieties",
      description: "varieties.limbras-22.description",
      features: [
        "varieties.limbras-22.features.0",
        "varieties.limbras-22.features.1",
        "varieties.limbras-22.features.2",
      ],
      images: ["/assets/img/limbras-22-1.webp", "/assets/img/limbras-22-2.webp"],
    },
    {
      id: "limbras-26",
      name: "varieties.limbras-26.name",
      category: "european-varieties",
      description: "varieties.limbras-26.description",
      features: [
        "varieties.limbras-26.features.0",
        "varieties.limbras-26.features.1",
        "varieties.limbras-26.features.2",
      ],
      images: ["/assets/img/limbras-26-1.webp", "/assets/img/limbras-26-2.webp"],
    },
    {
      id: "lucullus",
      name: "varieties.lucullus.name",
      category: "european-varieties",
      description: "varieties.lucullus.description",
      features: [
        "varieties.lucullus.features.0",
        "varieties.lucullus.features.1",
        "varieties.lucullus.features.2",
      ],
      images: ["/assets/img/lucullus-1.webp", "/assets/img/lucullus-2.webp"],
    },
    {
      id: "sequoia",
      name: "varieties.sequoia.name",
      category: "newer-hybrids",
      description: "varieties.sequoia.description",
      features: [
        "varieties.sequoia.features.0",
        "varieties.sequoia.features.1",
        "varieties.sequoia.features.2",
      ],
      images: ["/assets/img/sequoia-1.webp", "/assets/img/sequoia-2.webp"],
    },
    {
      id: "porthos",
      name: "varieties.porthos.name",
      category: "newer-hybrids",
      description: "varieties.porthos.description",
      features: [
        "varieties.porthos.features.0",
        "varieties.porthos.features.1",
        "varieties.porthos.features.2",
      ],
      images: ["/assets/img/porthos-1.webp", "/assets/img/porthos-2.webp"],
    },
    {
      id: "jersey-queen",
      name: "varieties.jersey-queen.name",
      category: "niche-varieties",
      description: "varieties.jersey-queen.description",
      features: [
        "varieties.jersey-queen.features.0",
        "varieties.jersey-queen.features.1",
        "varieties.jersey-queen.features.2",
      ],
      images: ["/assets/img/jersey-queen-1.webp", "/assets/img/jersey-queen-2.webp"],
    },
    {
      id: "idalee-f2",
      name: "varieties.idalee-f2.name",
      category: "niche-varieties",
      description: "varieties.idalee-f2.description",
      features: [
        "varieties.idalee-f2.features.0",
        "varieties.idalee-f2.features.1",
        "varieties.idalee-f2.features.2",
      ],
      images: ["/assets/img/idalee-f2-1.webp", "/assets/img/idalee-f2-2.webp"],
    },
  ],
};

export default varietiesData;
