import useMobileNav from "../hooks/useMobileNav";

const MobileNavToggle = () => {
  useMobileNav();

  return (
    <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
  );
};

export default MobileNavToggle;
