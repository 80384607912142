import React from "react";
import { useTranslation } from "react-i18next";
import heroImageBg from "../assets/img/hero-bg-light.webp";
import heroImage from "../assets/img/asparagus-front-img.webp";

const HeroSection = () => {
  const { t } = useTranslation();

  return (
    <section id="hero" className="hero section">
      <div className="hero-bg">
        <img src={heroImageBg} alt={t("hero.welcome")} />
      </div>
      <div className="container text-center">
        <h1 data-aos="fade-up">
          {t("hero.welcome")} <span>Bio Asparagus</span>
        </h1>
        <p data-aos="fade-up" data-aos-delay="100">
          {t("hero.tagline")}
        </p>
        <div data-aos="fade-up" data-aos-delay="200">
          <a href="#about" className="btn-get-started">
            {t("hero.aboutButton")}
          </a>
        </div>
        <img
          src={heroImage}
          className="img-fluid hero-img"
          alt=""
          data-aos="zoom-out"
          data-aos-delay="300"
        ></img>
      </div>
    </section>
  );
};

export default HeroSection;