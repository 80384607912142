import categories from './translations/categories';
import varieties from './translations/varieties';
import recipes from './translations/recipes';

const resources = {
  en: {
    translation: {
      categories: categories.en,
      varieties: varieties.en,
      recipes: recipes.en,
      "common":{
        "learnMore" : "Learn More"
      },
      "header": {
        "logoAlt": "Bio Asparagus Logo",
        "siteName": "Bio Asparagus",
        "contact": "Contact Us"
      },
      "footer": {
        "siteName": "Bio Asparagus",
        "addressLine1": "Mladost-4",
        "addressLine2": "Sofia, 1715",
        "phoneLabel": "Phone:",
        "phone": "+359 883 359 359",
        "emailLabel": "Email:",
        "email": "info@bioasparagus.com",
        "usefulLinksTitle": "Useful Links",
        "home": "Home",
        "about": "About Us",
        "newsletterTitle": "Our Newsletter",
        "newsletterDescription": "Subscribe to our newsletter and receive the latest updates about our products and services!",
        "emailPlaceholder": "Your Email",
        "subscribe": "Subscribe",
        "loading": "Loading",
        "successMessage": "Your subscription request has been sent. Thank you!",
        "copyrightLabel": "Copyright",
        "rightsReserved": "All rights reserved"
      },
      "contact": {
        "title": "Contact",
        "description": "Reach out to us for more information about our organic asparagus and sustainable farming practices.",
        "addressTitle": "Address",
        "address": "Sofia, Mladost-4, 1715",
        "phoneTitle": "Call Us",
        "phone": "+359 883 359 359",
        "emailTitle": "Email Us",
        "email": "info@bioasparagus.com",
        "mapTitle": "Our Location"
      },
      "hero": {
        "welcome": "Welcome to",
        "highlight": "Bio Asparagus",
        "tagline": "Organic Asparagus – Natural, Pure, and Healthy",
        "aboutButton": "About Us"
      },
      "about": {
        "whoWeAre": "Who We Are",
        "title": "Committed to Sustainable Organic Farming",
        "description": "At 'Bio Asparagus Farm,' we believe in caring for and respecting the land to produce high-quality organic asparagus for our community.",
        "features": [
          "We use sustainable agricultural practices to maintain soil health and ecosystem balance.",
          "Our farm produces fresh and high-quality asparagus, rich in nutrients and flavor.",
          "We value transparency so our customers know where their food comes from and how it’s grown."
        ],
        "readMore": "Learn More"
      },
      "commitment": {
        "title1": "Our Commitment to Organic Farming",
        "description1": "At 'Bio Asparagus Farm,' we are proud of our commitment to sustainable agriculture. Our methods focus on preserving soil health and ecological balance to provide you with the tastiest and most nutritious asparagus.",
        "title2": "Why Choose Our Asparagus",
        "description2": "Our asparagus is not only organic but also rich in vitamins and minerals, thanks to farming methods that combine tradition and innovation.",
        "features": [
          "Guaranteed quality and freshness.",
          "Certified organic production.",
          "Maintaining sustainability and ecological balance."
        ],
        "learnMore": "Learn More"
      },
      "variety": {
        "categoryTitle": "Asparagus Categories",
        "categoryDescription": "Discover a wide range of asparagus categories, each tailored to specific needs of growers and enthusiasts.",
        "notFound": "Category not found.",
        "explore": "Explore All Our Categories",
        "varietiesTitle": "Varieties in this Category",
        "about": "About"
      },
      "languageSwitcher": {
        "en": "English",
        "bg": "Bulgarian",
        "active": "Active"
      }
    },
  },
  bg: {
    translation: {
      categories: categories.bg,
      varieties: varieties.bg,
      recipes: recipes.bg,
      "common":{
        "learnMore" : "Научете повече"
      },
      "header": {
        "logoAlt": "Лого на Био Аспержи",
        "siteName": "Био Аспержи",
        "contact": "Контакти"
      },
      "footer": {
        "siteName": "Био Аспержи",
        "addressLine1": "Младост-4",
        "addressLine2": "София, 1715",
        "phoneLabel": "Телефон:",
        "phone": "+359 883 359 359",
        "emailLabel": "Имейл:",
        "email": "info@bioasparagus.com",
        "usefulLinksTitle": "Полезни връзки",
        "home": "Начало",
        "about": "За нас",
        "newsletterTitle": "Нашият бюлетин",
        "newsletterDescription": "Абонирайте се за нашия бюлетин и получавайте последните новини за нашите продукти и услуги!",
        "emailPlaceholder": "Вашият имейл",
        "subscribe": "Абонирай се",
        "loading": "Зареждане",
        "successMessage": "Вашата заявка за абонамент е изпратена. Благодарим Ви!",
        "copyrightLabel": "Авторско право",
        "rightsReserved": "Всички права запазени"
      },
      "contact": {
        "title": "Контакти",
        "description": "Свържете се с нас за повече информация относно нашите органични аспержи и устойчиви земеделски практики.",
        "addressTitle": "Адрес",
        "address": "София, Младост-4, 1715",
        "phoneTitle": "Обадете се",
        "phone": "+359 883 359 359",
        "emailTitle": "Пишете ни",
        "email": "info@bioasparagus.com",
        "mapTitle": "Нашето местоположение"
      },
      "hero": {
        "welcome": "Добре дошли в",
        "highlight": "Био Аспержи",
        "tagline": "Биологични аспержи – Натурално, Чисто и Здравословно",
        "aboutButton": "За Нас"
      },
      "about": {
        "whoWeAre": "Кои сме ние",
        "title": "Посветени на устойчивото органично земеделие",
        "description": "В 'Био ферма за аспержи' вярваме в грижата и уважението към земята, за да произвеждаме висококачествени органични аспержи за нашата общност.",
        "features": [
          "Използваме устойчиви земеделски практики, за да поддържаме здравето на почвата и екосистемата.",
          "Фермата ни произвежда свежи и висококачествени аспержи, богати на хранителни вещества и вкус.",
          "Отдаваме значение на прозрачността, за да могат нашите клиенти да знаят откъде идва храната им и как е отгледана."
        ],
        "readMore": "Научете повече"
      },
      "commitment": {
        "title1": "Нашият ангажимент към органичното земеделие",
        "description1": "В 'Био ферма за аспержи' се гордеем с нашия ангажимент към устойчивото земеделие. Нашите методи са насочени към запазване на почвеното здраве и екологичния баланс, за да ви предложим най-вкусните и хранителни аспержи.",
        "title2": "Защо да изберете нашите аспержи",
        "description2": "Нашите аспержи са не само органични, но и богати на витамини и минерали, благодарение на методите на отглеждане, които съчетават традиции и иновации.",
        "features": [
          "Гарантирано качество и свежест.",
          "Сертифицирано био производство.",
          "Поддържане на устойчивостта и екологичния баланс."
        ],
        "learnMore": "Научете повече"
      },
      "variety": {
        "categoryTitle": "Категории Аспержи",
        "categoryDescription": "Открийте широка гама от категории аспержи, всяка пригодена към специфичните нужди на производители и ентусиасти.",
        "notFound": "Категорията не е намерена.",
        "explore": "Разгледайте всички наши категории",
        "varietiesTitle": "Сортове в тази категория",
        "about": "Относно"
      },
      "languageSwitcher": {
        "en": "Английски",
        "bg": "Български",
        "active": "Активен"
      }
    },
  },
};

export default resources;
