const varieties = {
    en: {
        "jersey-giant": {
          "name": "Jersey Giant",
          "description": "Jersey Giant is a high-performing asparagus variety known for its excellent resistance to diseases such as rust and Fusarium. It produces large, tender spears, making it a favorite among commercial growers.",
          "features": [
            "Highly resistant to rust and Fusarium wilt.",
            "Produces large, tender spears with a mild flavor.",
            "Ideal for temperate and subtropical regions."
          ]
        },
        "jersey-knight": {
          "name": "Jersey Knight",
          "description": "Jersey Knight is a premium variety offering superior yields and robust disease resistance. Its spears are tender and flavorful, perfect for fresh markets.",
          "features": [
            "Excellent disease resistance to Fusarium and rust.",
            "Produces thick, high-quality spears.",
            "Ideal for commercial and home growers."
          ]
        },
        "jersey-supreme": {
          "name": "Jersey Supreme",
          "description": "Jersey Supreme is an early-maturing variety, ideal for growers looking to capture early-season markets. It offers a high yield of uniform spears.",
          "features": [
            "Early maturity for faster harvesting.",
            "High yield with uniform spear production.",
            "Strong resistance to common diseases."
          ]
        },
        "guelph-millennium": {
          "name": "Guelph Millennium",
          "description": "Guelph Millennium is a highly adaptable variety suitable for colder climates. It delivers consistent yields and is an excellent choice for growers in cooler regions.",
          "features": [
            "Cold-tolerant variety for northern regions.",
            "Consistent yields and uniform spears.",
            "Strong disease resistance."
          ]
        },
        "mondeo": {
          "name": "Mondeo",
          "description": "Mondeo is an early-maturing hybrid asparagus variety, perfect for commercial growers aiming for high yields and spear uniformity.",
          "features": [
            "Uniform spears with high-quality texture.",
            "Early-maturing and productive variety.",
            "Ideal for large-scale farming."
          ]
        },
        "purple-passion": {
          "name": "Purple Passion",
          "description": "Purple Passion is a standout variety with its deep purple spears and sweet flavor. It's perfect for fresh consumption and adds vibrant color to dishes.",
          "features": [
            "Distinctive purple color with sweet flavor.",
            "Tender texture, perfect for gourmet dishes.",
            "Unique addition to specialty markets."
          ]
        },
        "viola": {
          "name": "Viola",
          "description": "Viola is a gourmet purple asparagus variety known for its vibrant color and exceptional taste. Its spears are tender and perfect for salads and grilling.",
          "features": [
            "Rich purple spears with a tender bite.",
            "Excellent for fresh market and culinary use.",
            "Higher sugar content than green varieties."
          ]
        },
        "mary-washington": {
          "name": "Mary Washington",
          "description": "Mary Washington is a classic open-pollinated variety known for its reliability and adaptability. It produces flavorful spears and is ideal for home gardens and small farms.",
          "features": [
            "Reliable and easy to grow.",
            "Adaptable to various climates.",
            "Produces medium-sized spears with excellent flavor."
          ]
        },
        "martha-washington": {
          "name": "Martha Washington",
          "description": "Martha Washington is a time-tested variety favored for its disease resistance and consistent yields. It's a popular choice for traditional asparagus growers.",
          "features": [
            "High disease resistance, particularly to rust.",
            "Medium-sized spears with mild flavor.",
            "Perfect for small-scale and organic farming."
          ]
        },
        "viking-kb3": {
          "name": "Viking KB3",
          "description": "Viking KB3 is a robust open-pollinated variety known for its excellent tolerance to adverse weather conditions. It's suitable for growers in challenging climates.",
          "features": [
            "Resistant to drought and cold weather.",
            "Produces large, flavorful spears.",
            "Low-maintenance and adaptable variety."
          ]
        },
        "apollo": {
          "name": "Apollo",
          "description": "Apollo is a versatile asparagus variety that thrives in warm climates. Its vigorous growth and tender spears make it a favorite for subtropical regions.",
          "features": [
            "Heat-tolerant and drought-resistant.",
            "Produces large, tender spears with excellent flavor.",
            "Ideal for subtropical and tropical climates."
          ]
        },
        "atlas": {
          "name": "Atlas",
          "description": "Atlas is a highly productive asparagus variety bred for warm regions. It offers excellent yields and consistent spear quality under challenging conditions.",
          "features": [
            "High-yielding variety for warm climates.",
            "Resistant to heat stress and Fusarium wilt.",
            "Uniform spears with a mild flavor."
          ]
        },
        "grande": {
          "name": "Grande",
          "description": "Grande is a robust variety tailored for warm environments. Its thick spears and strong disease resistance make it a top choice for southern growers.",
          "features": [
            "Thick, flavorful spears with tender texture.",
            "Excellent resistance to heat and drought.",
            "Perfect for commercial and home gardens."
          ]
        },
        "jersey-king": {
          "name": "Jersey King",
          "description": "Jersey King is a premier asparagus variety with strong disease resistance and high yields. It thrives in regions where rust and Fusarium are prevalent.",
          "features": [
            "Exceptional resistance to common diseases.",
            "Thick, flavorful spears for fresh markets.",
            "Reliable performance in disease-prone areas."
          ]
        },
        "jersey-titan": {
          "name": "Jersey Titan",
          "description": "Jersey Titan is a high-performing variety known for its uniform spear production and strong resistance to asparagus diseases.",
          "features": [
            "Consistent yields with uniform spear growth.",
            "Highly resistant to rust and Fusarium wilt.",
            "Ideal for commercial growers."
          ]
        },
        "jersey-gem": {
          "name": "Jersey Gem",
          "description": "Jersey Gem is a top-tier variety offering excellent disease resistance and tender, flavorful spears. Perfect for both commercial and home growers.",
          "features": [
            "High resistance to asparagus diseases.",
            "Produces tender, sweet spears.",
            "Great for fresh market and processing."
          ]
        },
        "limbras-22": {
          "name": "Limbras 22",
          "description": "Limbras 22 is a European variety with strong adaptability and excellent disease resistance. It's known for its consistent yields and high-quality spears.",
          "features": [
            "Strong resistance to European diseases.",
            "Produces uniform, high-quality spears.",
            "Adaptable to a range of climates."
          ]
        },
        "limbras-26": {
          "name": "Limbras 26",
          "description": "Limbras 26 is a highly productive variety adapted for European markets. It offers uniform spears and excellent disease resistance.",
          "features": [
            "Excellent uniformity in spear production.",
            "Disease-resistant and low-maintenance.",
            "Perfect for European growing conditions."
          ]
        },
        "lucullus": {
          "name": "Lucullus",
          "description": "Lucullus is a robust European variety known for its adaptability and reliable yields. It's a great choice for growers seeking quality and consistency.",
          "features": [
            "Strong adaptability to European climates.",
            "High-yielding with excellent spear quality.",
            "Great for fresh markets and processing."
          ]
        },
        "sequoia": {
          "name": "Sequoia",
          "description": "Sequoia is a state-of-the-art hybrid offering exceptional productivity and disease resistance. It's an excellent choice for modern growers.",
          "features": [
            "High-yielding with uniform spear production.",
            "Adaptable to various climates.",
            "Resistant to rust and Fusarium."
          ]
        },
        "porthos": {
          "name": "Porthos",
          "description": "Porthos is a versatile hybrid known for its adaptability and high yields. It's suitable for growers looking for consistent performance.",
          "features": [
            "Uniform spears with excellent flavor.",
            "Adaptable to diverse growing conditions.",
            "Strong resistance to common asparagus diseases."
          ]
        },
        "jersey-queen": {
          "name": "Jersey Queen",
          "description": "Jersey Queen is a specialty variety known for its unique flavor and texture. It's ideal for niche markets and gourmet growers.",
          "features": [
            "Rich flavor with a tender bite.",
            "Perfect for specialty and gourmet markets.",
            "Produces uniform, high-quality spears."
          ]
        },
        "idalee-f2": {
          "name": "Idalee F2",
          "description": "Idalee F2 is an experimental variety known for its unique characteristics. It offers growers a chance to explore new possibilities in asparagus farming.",
          "features": [
            "Experimental variety with unique traits.",
            "Great for specialty and research-focused growers.",
            "Adaptable to various growing environments."
          ]
        }
      },
      bg:{
        "jersey-giant": {
          "name": "Джърси Гигант",
          "description": "Джърси Гигант е високопродуктивен сорт аспержи, известен с отличната си устойчивост на болести като ръжда и фузариум. Той произвежда големи, нежни стръкове, които са предпочитани от търговските производители.",
          "features": [
            "Висока устойчивост на ръжда и фузариум.",
            "Произвежда големи, нежни стръкове с мек вкус.",
            "Идеален за умерени и субтропични региони."
          ]
        },
        "jersey-knight": {
          "name": "Джърси Найт",
          "description": "Джърси Найт е премиум сорт, предлагащ високи добиви и здрава устойчивост на болести. Стръковете му са нежни и вкусни, перфектни за свежи пазари.",
          "features": [
            "Отлична устойчивост на фузариум и ръжда.",
            "Произвежда дебели, висококачествени стръкове.",
            "Идеален за търговски и домашни производители."
          ]
        },
        "jersey-supreme": {
          "name": "Джърси Суприм",
          "description": "Джърси Суприм е сорт с ранно узряване, идеален за производители, които искат да уловят пазара в началото на сезона. Той предлага висок добив на равномерни стръкове.",
          "features": [
            "Ранно узряване за по-бърза реколта.",
            "Висок добив с равномерно производство на стръкове.",
            "Силна устойчивост на често срещани заболявания."
          ]
        },
        "guelph-millennium": {
          "name": "Гуелф Милениум",
          "description": "Гуелф Милениум е високо адаптивен сорт, подходящ за по-студени климатични условия. Той осигурява постоянни добиви и е отличен избор за производителите в по-хладни региони.",
          "features": [
            "Студоустойчив сорт за северни региони.",
            "Консистентни добиви и равномерни стръкове.",
            "Силна устойчивост на болести."
          ]
        },
        "mondeo": {
          "name": "Мондео",
          "description": "Мондео е ранозрял хибриден сорт аспержи, идеален за търговски производители, търсещи високи добиви и равномерност на стръковете.",
          "features": [
            "Равномерни стръкове с високо качество.",
            "Ранозрял и продуктивен сорт.",
            "Идеален за земеделие в голям мащаб."
          ]
        },
        "mary-washington": {
          "name": "Мери Вашингтон",
          "description": "Мери Вашингтон е класически сорт със свободно опрашване, известен със своята надеждност и адаптивност. Той произвежда ароматни стръкове и е идеален за домашни градини и малки ферми.",
          "features": [
            "Надежден и лесен за отглеждане.",
            "Адаптивен към различни климатични условия.",
            "Произвежда средни по размер стръкове с отличен вкус."
          ]
        },
        "martha-washington": {
          "name": "Марта Вашингтон",
          "description": "Марта Вашингтон е изпитан сорт, предпочитан заради своята устойчивост на болести и постоянни добиви. Той е популярен избор за традиционните производители на аспержи.",
          "features": [
            "Висока устойчивост на болести, особено на ръжда.",
            "Средни по размер стръкове с мек вкус.",
            "Перфектен за малки ферми и органично земеделие."
          ]
        },
        "viking-kb3": {
          "name": "Викинг KB3",
          "description": "Викинг KB3 е здрав сорт със свободно опрашване, известен със своята отлична толерантност към неблагоприятни климатични условия. Подходящ е за производители в предизвикателни климатични зони.",
          "features": [
            "Устойчив на суша и студено време.",
            "Произвежда големи, ароматни стръкове.",
            "Лесен за поддръжка и адаптивен сорт."
          ]
        },
        "purple-passion": {
          "name": "Пърпъл Пашън",
          "description": "Пърпъл Пашън е забележителен сорт с дълбок лилав цвят и сладък вкус. Той е идеален за прясна консумация и добавя ярък цвят към ястията.",
          "features": [
            "Отличителен лилав цвят със сладък вкус.",
            "Нежна текстура, перфектна за гурме ястия.",
            "Уникално допълнение за специализирани пазари."
          ]
        },
        "viola": {
          "name": "Виола",
          "description": "Виола е гурме лилав сорт аспержи, известен със своя ярък цвят и изключителен вкус. Стръковете му са нежни и идеални за салати и грил.",
          "features": [
            "Наситено лилави стръкове с нежна текстура.",
            "Отлични за пресни пазари и кулинарна употреба.",
            "По-високо съдържание на захар в сравнение със зелените сортове."
          ]
        },
        "apollo": {
          "name": "Аполон",
          "description": "Аполон е универсален сорт аспержи, който вирее в топъл климат. Неговият енергичен растеж и нежни стръкове го правят предпочитан за субтропични региони.",
          "features": [
            "Топлоустойчив и устойчив на засушаване.",
            "Произвежда големи, нежни стръкове с отличен вкус.",
            "Идеален за субтропичен и тропичен климат."
          ]
        },
        "atlas": {
          "name": "Атлас",
          "description": "Атлас е високопродуктивен сорт аспержи, създаден за топли региони. Той предлага отлични добиви и последователно качество на стръковете дори при трудни условия.",
          "features": [
            "Високопродуктивен сорт за топъл климат.",
            "Устойчив на топлинен стрес и фузариум.",
            "Равномерни стръкове с мек вкус."
          ]
        },
        "grande": {
          "name": "Гранде",
          "description": "Гранде е устойчив сорт, пригоден за топли среди. Неговите дебели стръкове и силна устойчивост на болести го правят топ избор за южните производители.",
          "features": [
            "Дебели, вкусни стръкове с нежна текстура.",
            "Отлична устойчивост на топлина и засушаване.",
            "Перфектен за търговски и домашни градини."
          ]
        },
        "jersey-king": {
          "name": "Джърси Кинг",
          "description": "Джърси Кинг е премиум сорт аспержи с висока устойчивост на болести и високи добиви. Той вирее в региони, където ръждата и фузариумът са често срещани.",
          "features": [
            "Изключителна устойчивост на често срещани заболявания.",
            "Дебели, вкусни стръкове за пресни пазари.",
            "Надеждно представяне в райони с болести."
          ]
        },
        "jersey-titan": {
          "name": "Джърси Титан",
          "description": "Джърси Титан е високопродуктивен сорт, известен с равномерното производство на стръкове и силната устойчивост на заболявания при аспержите.",
          "features": [
            "Постоянни добиви с равномерни стръкове.",
            "Висока устойчивост на ръжда и фузариум.",
            "Идеален за търговски производители."
          ]
        },
        "jersey-gem": {
      "name": "Джърси Джем",
      "description": "Джърси Джем е първокласен сорт, който предлага отлична устойчивост на болести и нежни, ароматни стръкове. Перфектен за търговски и домашни производители.",
      "features": [
        "Висока устойчивост на болести по аспержите.",
        "Произвежда нежни и сладки стръкове.",
        "Отличен за пресните пазари и обработка."
      ]
    },
        "limbras-22": {
          "name": "Лимбрас 22",
          "description": "Лимбрас 22 е европейски сорт с висока устойчивост на болести и адаптивност. Известен е със своите последователни добиви и висококачествени стръкове.",
          "features": [
            "Силна устойчивост на европейски болести.",
            "Произвежда равномерни и висококачествени стръкове.",
            "Адаптивен към различни климатични условия."
          ]
        },
        "limbras-26": {
      "name": "Лимбрас 26",
      "description": "Лимбрас 26 е високопродуктивен сорт, адаптиран за европейските пазари. Предлага еднакви стръкове и отлична устойчивост на болести.",
      "features": [
        "Отлична еднаквост в производството на стръкове.",
        "Устойчив на болести и лесен за поддръжка.",
        "Перфектен за европейски условия на отглеждане."
      ]
    },
        "lucullus": {
          "name": "Лукулус",
          "description": "Лукулус е здрав европейски сорт, известен със своята адаптивност и надеждни добиви. Отличен избор за производители, които търсят качество и последователност.",
          "features": [
            "Силна адаптивност към европейските климатични условия.",
            "Високодобивен с отлични стръкове.",
            "Отличен за пресни пазари и обработка."
          ]
        },
        "sequoia": {
          "name": "Секвоя",
          "description": "Секвоя е иновативен хибрид, предлагащ изключителна продуктивност и устойчивост на болести. Отличен избор за модерните производители.",
          "features": [
            "Висок добив с равномерно производство на стръкове.",
            "Адаптивен към различни климатични условия.",
            "Устойчив на ръжда и фузариум."
          ]
        },
        "porthos": {
      "name": "Портос",
      "description": "Портос е универсален хибрид, известен със своята адаптивност и високи добиви. Подходящ е за производители, търсещи постоянни резултати.",
      "features": [
        "Еднакви стръкове с отличен вкус.",
        "Адаптивен към разнообразни условия на отглеждане.",
        "Силна устойчивост към общи болести по аспержите."
      ]
    }
    ,
    "jersey-queen": {
          "name": "Джърси Куин",
          "description": "Джърси Куин е специализиран сорт, известен със своя уникален вкус и текстура. Идеален за нишови пазари и гурме производители.",
          "features": [
            "Богат вкус с нежна текстура.",
            "Идеален за специализирани и гурме пазари.",
            "Произвежда равномерни, висококачествени стръкове."
          ]
        },
        "idalee-f2": {
          "name": "Идали Ф2",
          "description": "Идали Ф2 е експериментален сорт, известен със своите уникални характеристики. Той предлага възможност на производителите да изследват нови възможности.",
          "features": [
            "Експериментален сорт с уникални черти.",
            "Идеален за специализирани и изследователски производители.",
            "Адаптивен към различни условия за отглеждане."
          ]
        }
        
      }
    }

    export default varieties;