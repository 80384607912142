import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";
import ScrollTopButton from "./components/ScrollTopButton";
import Home from "./pages/Home";
import Contact from "./components/ContactSection";
import CategoryPage from "./pages/categories/CategoryPage";
import VarietyPage from "./pages/varieties/VarietyPage"
import RecipesPage from "./pages/recipes/RecipesPage";
import RecipePage from "./pages/recipes/RecipePage";
import useToggleScrolled from "./hooks/useToggleScrolled";
import usePreloader from "./hooks/usePreloader";
import useAOS from "./hooks/useAOS";
import useNavMenuScrollspy from "./hooks/useNavMenuScrollspy";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "aos/dist/aos.css";
import "glightbox/dist/css/glightbox.css";
import "swiper/swiper-bundle.css";
import "./assets/css/main.css";


const App = () => {
  useToggleScrolled();
  usePreloader();
  useAOS();
  useNavMenuScrollspy();
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/varieties" element={<CategoryPage />} />
        <Route path="/varieties/:categoryId?" element={<CategoryPage />} />
        <Route path="/varieties/:categoryId/:varietyId" element={<VarietyPage />} />
        <Route path="/recipes/:recipeId" element={<RecipePage />} />
        <Route path="/recipes" element={<RecipesPage />} />
      </Routes>

      <Header />
      <Footer /> 
      <ScrollTopButton />
    </Router>
  );
};

export default App;