import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import data from "../../data/varieties";
import VarietyCard from "../../components/VarietyCard";
import heroImageBg from "../../assets/img/hero-bg-light.webp";

const VarietyPage = () => {
  const { t } = useTranslation();
  const { categoryId, varietyId } = useParams();

  // Find the variety based on the route parameter
  const variety = data.varieties.find(
    (variety) => variety.id === varietyId && variety.category === categoryId
  );

  if (!variety) {
    return <p>{t("varieties.notFound")}</p>;
  }

  return (
    <section id="hero" className="hero section">
      <div className="hero-bg">
        <img src={heroImageBg} alt={t("varieties.header")} />
      </div>
      <div className="container">
        <VarietyCard variety={variety} />
      </div>
    </section>
  );
};

export default VarietyPage;
