import React from "react";
import { useTranslation } from "react-i18next";
import recipesData from "../../data/recipes";
import heroImageBg from "../../assets/img/hero-bg-light.webp";

const RecipesPage = () => {
  const { t } = useTranslation();

  return (
    <section id="hero" className="hero section">
        <div className="hero-bg">
          <img src={heroImageBg} alt={t("variety.heroAlt")} />
        </div>
        <div className="container">
          <div className="row gy-4">
            <div
              className="col-lg-12 content"
              data-aos="fade-up"
              data-aos-delay="100"
            >

        <h3>{t("recipes.header")}</h3>
        <ul>
          {recipesData.map((recipe) => (
            <li key={recipe.id}>
            <a href={`/recipes/${recipe.id}`} alt={t(recipe.description)}>
              {t(recipe.title)}{" "}
              <i className="bi bi-arrow-right"></i>
            </a>
          </li>
           ))}
          </ul>
        </div>
        </div>
      </div>
    </section>
  );
};

export default RecipesPage;