import React from "react";
import { useTranslation } from "react-i18next";
import asparagus0 from "../assets/img/asparagus-0.webp";
import asparagus2 from "../assets/img/asparagus-2.webp";
import asparagus3 from "../assets/img/asparagus-3.webp";

const AboutSection = () => {
  const { t } = useTranslation();

  return (
    <section id="about" className="about section">
      <div className="container">
        <div className="row gy-4">
          <div className="col-lg-6 content" data-aos="fade-up" data-aos-delay="100">
            <p className="who-we-are">{t("about.whoWeAre")}</p>
            <h3>{t("about.title")}</h3>
            <p className="fst-italic">{t("about.description")}</p>
            <ul>
              {t("about.features", { returnObjects: true }).map((feature, index) => (
                <li key={index}>
                  <i className="bi bi-check-circle"></i> <span>{feature}</span>
                </li>
              ))}
            </ul>
            <a href="#" className="read-more">
              <span>{t("about.readMore")}</span>
              <i className="bi bi-arrow-right"></i>
            </a>
          </div>
          <div className="col-lg-6 about-images" data-aos="fade-up" data-aos-delay="200">
            <div className="row gy-4">
              <div className="col-lg-6">
                <img src={asparagus0} className="img-fluid" alt="Asparagus field 1" />
              </div>
              <div className="col-lg-6">
                <div className="row gy-4">
                  <div className="col-lg-12">
                    <img src={asparagus2} className="img-fluid" alt="Asparagus field 2" />
                  </div>
                  <div className="col-lg-12">
                    <img src={asparagus3} className="img-fluid" alt="Asparagus harvest" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;