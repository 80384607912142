import React from "react";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./LanguageSwitcher";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer id="footer" className="footer position-relative light-background">
      {/* Footer Top Section */}
      <div className="container footer-top">
        <div className="row gy-4">
          {/* About Section */}
          <div className="col-lg-4 col-md-6 footer-about">
            <a href="/" className="logo d-flex align-items-center">
              <span className="sitename">{t("footer.siteName")}</span>
            </a>
            <div className="footer-contact pt-3">
              <p>{t("footer.addressLine1")}</p>
              <p>{t("footer.addressLine2")}</p>
              <p className="mt-3">
                <strong>{t("footer.phoneLabel")}</strong> <span>{t("footer.phone")}</span>
              </p>
              <p>
                <strong>{t("footer.emailLabel")}</strong> <span>{t("footer.email")}</span>
              </p>
            </div>
            <div className="social-links d-flex mt-4">
              <a href="#" title="Twitter">
                <i className="bi bi-twitter-x"></i>
              </a>
              <a href="#" title="Facebook">
                <i className="bi bi-facebook"></i>
              </a>
              <a href="#" title="Instagram">
                <i className="bi bi-instagram"></i>
              </a>
              <a href="#" title="LinkedIn">
                <i className="bi bi-linkedin"></i>
              </a>
            </div>
          </div>

          {/* Useful Links */}
          <div className="col-lg-4 col-md-6 footer-links">
            <h4>{t("footer.usefulLinksTitle")}</h4>
            <ul>
              <li>
                <a href="/">{t("footer.home")}</a>
              </li>
              <li>
                <a href="#about">{t("footer.about")}</a>
              </li>
            </ul>
          </div>

          {/* Newsletter Section */}
          <div className="col-lg-4 col-md-12 footer-newsletter">
            <h4>{t("footer.newsletterTitle")}</h4>
            <p>{t("footer.newsletterDescription")}</p>
            <form action="/forms/newsletter.php" method="post" className="php-email-form">
              <div className="newsletter-form">
                <input type="email" name="email" placeholder={t("footer.emailPlaceholder")} />
                <input type="submit" value={t("footer.subscribe")} />
              </div>
              <div className="loading">{t("footer.loading")}</div>
              <div className="error-message"></div>
              <div className="sent-message">{t("footer.successMessage")}</div>
            </form>
          </div>
        </div>
      </div>

      {/* Copyright Section */}
      <div className="container copyright text-center mt-4">
        <p>
          © {t("footer.copyrightLabel")}{" "}
          <strong className="px-1 sitename">{t("footer.siteName")}</strong>{" "}
          {t("footer.rightsReserved")}.
        </p>
      </div>

      <LanguageSwitcher />
    </footer>
  );
};

export default Footer;
