const recipesData = [
  {
    id: "recipe1",
    title: "recipes.recipe1.title",
    description: "recipes.recipe1.description",
    ingredients: ["recipes.recipe1.ingredients.0", "recipes.recipe1.ingredients.1", "recipes.recipe1.ingredients.2", "recipes.recipe1.ingredients.3", "recipes.recipe1.ingredients.4"],
    instructions: "recipes.recipe1.instructions"
  },
  {
    id: "recipe2",
    title: "recipes.recipe2.title",
    description: "recipes.recipe2.description",
    ingredients: ["recipes.recipe2.ingredients.0", "recipes.recipe2.ingredients.1", "recipes.recipe2.ingredients.2", "recipes.recipe2.ingredients.3", "recipes.recipe2.ingredients.4", "recipes.recipe2.ingredients.5"],
    instructions: "recipes.recipe2.instructions"
  },
  {
    id: "recipe3",
    title: "recipes.recipe3.title",
    description: "recipes.recipe3.description",
    ingredients: ["recipes.recipe3.ingredients.0", "recipes.recipe3.ingredients.1", "recipes.recipe3.ingredients.2", "recipes.recipe3.ingredients.3", "recipes.recipe3.ingredients.4", "recipes.recipe3.ingredients.5"],
    instructions: "recipes.recipe3.instructions"
  },
  {
    id: "recipe4",
    title: "recipes.recipe4.title",
    description: "recipes.recipe4.description",
    ingredients: ["recipes.recipe4.ingredients.0", "recipes.recipe4.ingredients.1", "recipes.recipe4.ingredients.2", "recipes.recipe4.ingredients.3", "recipes.recipe4.ingredients.4"],
    instructions: "recipes.recipe4.instructions"
  },
  {
    id: "recipe5",
    title: "recipes.recipe5.title",
    description: "recipes.recipe5.description",
    ingredients: ["recipes.recipe5.ingredients.0", "recipes.recipe5.ingredients.1", "recipes.recipe5.ingredients.2", "recipes.recipe5.ingredients.3", "recipes.recipe5.ingredients.4"],
    instructions: "recipes.recipe5.instructions"
  },
  {
    id: "recipe6",
    title: "recipes.recipe6.title",
    description: "recipes.recipe6.description",
    ingredients: ["recipes.recipe6.ingredients.0", "recipes.recipe6.ingredients.1", "recipes.recipe6.ingredients.2", "recipes.recipe6.ingredients.3", "recipes.recipe6.ingredients.4"],
    instructions: "recipes.recipe6.instructions"
  },
  {
    id: "recipe7",
    title: "recipes.recipe7.title",
    description: "recipes.recipe7.description",
    ingredients: ["recipes.recipe7.ingredients.0", "recipes.recipe7.ingredients.1", "recipes.recipe7.ingredients.2", "recipes.recipe7.ingredients.3", "recipes.recipe7.ingredients.4"],
    instructions: "recipes.recipe7.instructions"
  },
  {
    id: "recipe8",
    title: "recipes.recipe8.title",
    description: "recipes.recipe8.description",
    ingredients: ["recipes.recipe8.ingredients.0", "recipes.recipe8.ingredients.1", "recipes.recipe8.ingredients.2", "recipes.recipe8.ingredients.3"],
    instructions: "recipes.recipe8.instructions"
  },
  {
    id: "recipe9",
    title: "recipes.recipe9.title",
    description: "recipes.recipe9.description",
    ingredients: ["recipes.recipe9.ingredients.0", "recipes.recipe9.ingredients.1", "recipes.recipe9.ingredients.2", "recipes.recipe9.ingredients.3", "recipes.recipe9.ingredients.4"],
    instructions: "recipes.recipe9.instructions"
  },
  {
    id: "recipe10",
    title: "recipes.recipe10.title",
    description: "recipes.recipe10.description",
    ingredients: ["recipes.recipe10.ingredients.0", "recipes.recipe10.ingredients.1", "recipes.recipe10.ingredients.2", "recipes.recipe10.ingredients.3", "recipes.recipe10.ingredients.4"],
    instructions: "recipes.recipe10.instructions"
  },
  {
    id: "recipe11",
    title: "recipes.recipe11.title",
    description: "recipes.recipe11.description",
    ingredients: ["recipes.recipe11.ingredients.0", "recipes.recipe11.ingredients.1", "recipes.recipe11.ingredients.2", "recipes.recipe11.ingredients.3", "recipes.recipe11.ingredients.4"],
    instructions: "recipes.recipe11.instructions"
  },
  {
    id: "recipe12",
    title: "recipes.recipe12.title",
    description: "recipes.recipe12.description",
    ingredients: ["recipes.recipe12.ingredients.0", "recipes.recipe12.ingredients.1", "recipes.recipe12.ingredients.2", "recipes.recipe12.ingredients.3", "recipes.recipe12.ingredients.4", "recipes.recipe12.ingredients.5"],
    instructions: "recipes.recipe12.instructions"
  },
  {
    id: "recipe13",
    title: "recipes.recipe13.title",
    description: "recipes.recipe13.description",
    ingredients: ["recipes.recipe13.ingredients.0", "recipes.recipe13.ingredients.1", "recipes.recipe13.ingredients.2", "recipes.recipe13.ingredients.3", "recipes.recipe13.ingredients.4"],
    instructions: "recipes.recipe13.instructions"
  },
  {
    id: "recipe14",
    title: "recipes.recipe14.title",
    description: "recipes.recipe14.description",
    ingredients: ["recipes.recipe14.ingredients.0", "recipes.recipe14.ingredients.1", "recipes.recipe14.ingredients.2", "recipes.recipe14.ingredients.3", "recipes.recipe14.ingredients.4"],
    instructions: "recipes.recipe14.instructions"
  },
  {
    id: "recipe15",
    title: "recipes.recipe15.title",
    description: "recipes.recipe15.description",
    ingredients: ["recipes.recipe15.ingredients.0", "recipes.recipe15.ingredients.1", "recipes.recipe15.ingredients.2", "recipes.recipe15.ingredients.3", "recipes.recipe15.ingredients.4"],
    instructions: "recipes.recipe15.instructions"
  },
  {    
    id: "recipe16",
    title: "recipes.recipe16.title",
    description: "recipes.recipe16.description",
    ingredients: ["recipes.recipe16.ingredients.0", "recipes.recipe16.ingredients.1", "recipes.recipe16.ingredients.2", "recipes.recipe16.ingredients.3", "recipes.recipe16.ingredients.4"],
    instructions: "recipes.recipe16.instructions"
  },
];

export default recipesData;