import React from "react";
import { useTranslation } from "react-i18next";
import asparagus4 from "../assets/img/asparagus-4.webp";
import asparagus5 from "../assets/img/asparagus-5.webp";

const OurCommitmentSection = () => {
  const { t } = useTranslation();

  return (
    <section id="features-details" className="features-details section">
      <div className="container">
        {/* First Feature Item */}
        <div className="row gy-4 justify-content-between features-item">
          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
            <img src={asparagus4} className="img-fluid" alt={t("commitment.alt1")} />
          </div>
          <div className="col-lg-5 d-flex align-items-center" data-aos="fade-up" data-aos-delay="200">
            <div className="content">
              <h3>{t("commitment.title1")}</h3>
              <p>{t("commitment.description1")}</p>
              <a href="#" className="btn more-btn">
                {t("common.learnMore")}
              </a>
            </div>
          </div>
        </div>

        {/* Second Feature Item */}
        <div className="row gy-4 justify-content-between features-item">
          <div className="col-lg-5 d-flex align-items-center order-2 order-lg-1" data-aos="fade-up" data-aos-delay="100">
            <div className="content">
              <h3>{t("commitment.title2")}</h3>
              <p>{t("commitment.description2")}</p>
              <ul>
                {t("commitment.features", { returnObjects: true }).map((feature, index) => (
                  <li key={index}>
                    <i className="bi bi-check-circle"></i> <span>{feature}</span>
                  </li>
                ))}
              </ul>
              <a href="#" className="btn more-btn">
                {t("common.learnMore")}
              </a>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-up" data-aos-delay="200">
            <img src={asparagus5} className="img-fluid" alt={t("commitment.alt2")} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurCommitmentSection;
