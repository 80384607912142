import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import recipes from "../../data/recipes";
import RecipeCard from "../../components/RecipeCard";
import heroImageBg from "../../assets/img/hero-bg-light.webp";

const RecipePage = () => {
  const { t } = useTranslation();
  const { recipeId } = useParams();

  // Find the recipe based on the route parameter
  const recipe = recipes.find((recipe) => recipe.id === recipeId);

  if (!recipe) {
    return <p>{t("recipes.notFound")}</p>;
  }

  return (
    <section id="hero" className="hero section">
        <div className="hero-bg">
          <img src={heroImageBg} alt={t("variety.heroAlt")} />
        </div>
        <div className="container">
        <RecipeCard recipe={recipe} />
      </div>
    </section>
  );
};

export default RecipePage;