const recipes = {
    en: {
        ingredients: "Ingredients",
            instructions: "Instructions",
      recipe1: {
        title: "Grilled Asparagus",
        description: "Simple and flavorful grilled asparagus with olive oil and garlic.",
        ingredients: ["Asparagus", "Olive oil", "Garlic", "Salt", "Pepper"],
        instructions: "Toss asparagus with olive oil, minced garlic, salt, and pepper. Grill for 5-7 minutes, turning occasionally."
      },
      recipe2: {
        title: "Cream of Asparagus Soup",
        description: "A creamy and comforting asparagus soup, perfect for spring.",
        ingredients: ["Asparagus", "Butter", "Onion", "Garlic", "Vegetable broth", "Heavy cream"],
        instructions: "Sauté onions and garlic in butter. Add chopped asparagus and broth, simmer until tender. Blend until smooth, then stir in cream."
      },
      recipe3: {
        title: "Asparagus Risotto",
        description: "A rich and creamy risotto featuring fresh asparagus.",
        ingredients: ["Asparagus", "Arborio rice", "Onion", "Garlic", "Vegetable broth", "Parmesan cheese"],
        instructions: "Sauté onions and garlic, then toast the rice. Add broth gradually while stirring. Fold in cooked asparagus and Parmesan cheese."
      },
      recipe4: {
        title: "Asparagus Frittata",
        description: "A quick and easy frittata with asparagus and cheese.",
        ingredients: ["Asparagus", "Eggs", "Milk", "Cheddar cheese", "Salt", "Pepper"],
        instructions: "Whisk eggs and milk, then stir in cooked asparagus and cheese. Pour into a greased skillet and bake at 180°C for 20 minutes."
      },
      recipe5: {
        title: "Asparagus Stir-Fry",
        description: "A quick and flavorful stir-fry with asparagus and soy sauce.",
        ingredients: ["Asparagus", "Soy sauce", "Garlic", "Ginger", "Sesame oil"],
        instructions: "Heat sesame oil in a pan, add minced garlic and ginger. Toss in asparagus and stir-fry for 5 minutes. Add soy sauce and cook for 2 more minutes."
      },
      recipe6: {
        title: "Asparagus Pasta",
        description: "A light pasta dish with asparagus and lemon.",
        ingredients: ["Pasta", "Asparagus", "Lemon zest", "Olive oil", "Parmesan cheese"],
        instructions: "Cook pasta and toss with steamed asparagus, olive oil, lemon zest, and Parmesan cheese."
      },
      recipe7: {
        title: "Bacon-Wrapped Asparagus",
        description: "Crispy bacon-wrapped asparagus spears, great as an appetizer.",
        ingredients: ["Asparagus", "Bacon", "Olive oil", "Salt", "Pepper"],
        instructions: "Wrap each asparagus spear with bacon. Bake at 200°C for 15-20 minutes until bacon is crispy."
      },
      recipe8: {
        title: "Roasted Asparagus with Parmesan",
        description: "A simple roasted asparagus recipe topped with Parmesan cheese.",
        ingredients: ["Asparagus", "Olive oil", "Parmesan cheese", "Salt", "Pepper"],
        instructions: "Toss asparagus with olive oil, salt, and pepper. Roast at 200°C for 12 minutes, then sprinkle with Parmesan."
      },
      recipe9: {
        title: "Asparagus and Goat Cheese Tart",
        description: "A flaky pastry tart with asparagus and creamy goat cheese.",
        ingredients: ["Puff pastry", "Asparagus", "Goat cheese", "Egg", "Cream"],
        instructions: "Spread goat cheese on puff pastry, arrange asparagus on top. Brush edges with egg wash and bake at 200°C for 20 minutes."
      },
      recipe10: {
        title: "Asparagus Quiche",
        description: "A rich and savory quiche with asparagus and Gruyère cheese.",
        ingredients: ["Asparagus", "Eggs", "Milk", "Gruyère cheese", "Pie crust"],
        instructions: "Line a pie crust with blanched asparagus. Mix eggs, milk, and cheese, pour over asparagus. Bake at 180°C for 30-35 minutes."
      },
      recipe11: {
        title: "Asparagus and Prosciutto Salad",
        description: "A refreshing salad with asparagus, prosciutto, and balsamic glaze.",
        ingredients: ["Asparagus", "Prosciutto", "Arugula", "Balsamic glaze", "Olive oil"],
        instructions: "Arrange blanched asparagus, prosciutto, and arugula on a plate. Drizzle with balsamic glaze and olive oil."
      },
      recipe12: {
        title: "Asparagus Tempura",
        description: "Crispy fried asparagus spears in a light tempura batter.",
        ingredients: ["Asparagus", "Flour", "Cornstarch", "Egg", "Ice water", "Vegetable oil"],
        instructions: "Mix tempura batter. Dip asparagus and fry in hot oil until golden and crispy."
      },
      recipe13: {
        title: "Lemon Garlic Asparagus",
        description: "A zesty side dish of asparagus with lemon and garlic.",
        ingredients: ["Asparagus", "Lemon juice", "Garlic", "Olive oil", "Salt", "Pepper"],
        instructions: "Sauté asparagus in olive oil with minced garlic. Add lemon juice, salt, and pepper before serving."
      },
      recipe14: {
        title: "Asparagus Pizza",
        description: "A unique pizza topped with asparagus, mozzarella, and ricotta.",
        ingredients: ["Pizza dough", "Asparagus", "Mozzarella", "Ricotta", "Olive oil"],
        instructions: "Spread ricotta on pizza dough, top with asparagus and mozzarella. Drizzle with olive oil and bake at 220°C for 15 minutes."
      },
      recipe15: {
        title: "Asparagus and Shrimp Stir-Fry",
        description: "A flavorful stir-fry combining asparagus and shrimp with soy-ginger sauce.",
        ingredients: ["Asparagus", "Shrimp", "Soy sauce", "Garlic", "Ginger", "Sesame oil"],
        instructions: "Heat sesame oil, stir-fry garlic and ginger. Add shrimp and asparagus, cook for 5 minutes. Stir in soy sauce and serve."
      },
      recipe16: {
        title: "Asparagus Salad",
        description: "A delicious and healthy asparagus salad.",
        ingredients: ["Asparagus", "Olive oil", "Lemon juice", "Salt", "Pepper"],
        instructions: "Mix all ingredients and serve fresh."
      }
    },
    bg: {
            ingredients: "Съставки",
            instructions: "Инструкции",
      recipe1: {
        title: "Гриловани аспержи",
        description: "Прости и вкусни гриловани аспержи с маслиново масло и чесън.",
        ingredients: ["Аспержи", "Маслиново масло", "Чесън", "Сол", "Пипер"],
        instructions: "Смесете аспержите с маслиново масло, нарязан чесън, сол и пипер. Гриловайте за 5-7 минути, като обръщате от време на време."
      },
      recipe2: {
        title: "Крем супа от аспержи",
        description: "Кремообразна и утешителна супа от аспержи, идеална за пролетта.",
        ingredients: ["Аспержи", "Масло", "Лук", "Чесън", "Зеленчуков бульон", "Сметана"],
        instructions: "Запържете лука и чесъна в масло. Добавете нарязаните аспержи и бульона, варете до омекване. Пасирайте до гладкост, след това добавете сметаната."
      },
      recipe3: {
        title: "Ризото с аспержи",
        description: "Богато и кремообразно ризото с пресни аспержи.",
        ingredients: ["Аспержи", "Ориз Арборио", "Лук", "Чесън", "Зеленчуков бульон", "Пармезан"],
        instructions: "Запържете лука и чесъна, след това запечете ориза. Добавяйте бульона постепенно, като разбърквате. Добавете сварените аспержи и пармезана."
      },
      recipe4: {
        title: "Фритата с аспержи",
        description: "Бърза и лесна фритата с аспержи и сирене.",
        ingredients: ["Аспержи", "Яйца", "Мляко", "Чедър", "Сол", "Пипер"],
        instructions: "Разбийте яйцата и млякото, след това добавете сварените аспержи и сиренето. Изсипете в намазнена тава и печете на 180°C за 20 минути."
      },
      recipe5: {
        title: "Аспержи със соев сос",
        description: "Бързо и вкусно ястие с аспержи и соев сос.",
        ingredients: ["Аспержи", "Соев сос", "Чесън", "Джинджифил", "Сусамово масло"],
        instructions: "Загрейте сусамовото масло в тиган, добавете нарязания чесън и джинджифил. Добавете аспержите и запържете за 5 минути. Добавете соевия сос и гответе още 2 минути."
      },
      recipe6: {
        title: "Паста с аспержи",
        description: "Леко ястие с паста и аспержи с лимон.",
        ingredients: ["Паста", "Аспержи", "Лимонова кора", "Маслиново масло", "Пармезан"],
        instructions: "Сварете пастата и я смесете с аспержите, маслиновото масло, лимоновата кора и пармезана."
      },
      recipe7: {
        title: "Аспержи увити в бекон",
        description: "Хрупкави аспержи увити в бекон, идеални за предястие.",
        ingredients: ["Аспержи", "Бекон", "Маслиново масло", "Сол", "Пипер"],
        instructions: "Увийте всяка аспержа с бекон. Печете на 200°C за 15-20 минути до хрупкавост на бекона."
      },
      recipe8: {
        title: "Печени аспержи с пармезан",
        description: "Проста рецепта за печени аспержи с пармезан.",
        ingredients: ["Аспержи", "Маслиново масло", "Пармезан", "Сол", "Пипер"],
        instructions: "Смесете аспержите с маслиново масло, сол и пипер. Печете на 200°C за 12 минути, след това поръсете с пармезан."
      },
      recipe9: {
        title: "Тарт с аспержи и козе сирене",
        description: "Хрупкав тарт с аспержи и кремообразно козе сирене.",
        ingredients: ["Бутер тесто", "Аспержи", "Козе сирене", "Яйце", "Сметана"],
        instructions: "Намажете бутер тестото с козе сирене, подредете аспержите отгоре. Намажете краищата с яйце и печете на 200°C за 20 минути."
      },
      recipe10: {
        title: "Киш с аспержи",
        description: "Богат и вкусен киш с аспержи и сирене Грюер.",
        ingredients: ["Аспержи", "Яйца", "Мляко", "Сирене Грюер", "Тесто за пай"],
        instructions: "Подредете бланшираните аспержи в тестото за пай. Смесете яйцата, млякото и сиренето, изсипете върху аспержите. Печете на 180°C за 30-35 минути."
      },
      recipe11: {
        title: "Салата с аспержи и прошуто",
        description: "Освежаваща салата с аспержи, прошуто и балсамов глейз.",
        ingredients: ["Аспержи", "Прошуто", "Рукола", "Балсамов глейз", "Маслиново масло"],
        instructions: "Подредете бланшираните аспержи, прошуто и рукола в чиния. Полейте с балсамов глейз и маслиново масло."
      },
      recipe12: {
        title: "Темпура от аспержи",
        description: "Хрупкави пържени аспержи в лека темпура.",
        ingredients: ["Аспержи", "Брашно", "Царевично нишесте", "Яйце", "Ледена вода", "Растително масло"],
        instructions: "Смесете темпура тестото. Потопете аспержите и ги изпържете в горещо масло до златисто и хрупкаво."
      },
      recipe13: {
        title: "Аспержи с лимон и чесън",
        description: "Зестно ястие с аспержи с лимон и чесън.",
        ingredients: ["Аспержи", "Лимонов сок", "Чесън", "Маслиново масло", "Сол", "Пипер"],
        instructions: "Запържете аспержите в маслиново масло с нарязан чесън. Добавете лимонов сок, сол и пипер преди сервиране."
      },
      recipe14: {
        title: "Пица с аспержи",
        description: "Уникална пица с аспержи, моцарела и рикота.",
        ingredients: ["Тесто за пица", "Аспержи", "Моцарела", "Рикота", "Маслиново масло"],
        instructions: "Намажете тестото за пица с рикота, подредете аспержите и моцарелата отгоре. Полейте с маслиново масло и печете на 220°C за 15 минути."
      },
      recipe15: {
        title: "Аспержи и скариди със соев сос",
        description: "Вкусно ястие с аспержи и скариди със соево-джинджифилов сос.",
        ingredients: ["Аспержи", "Скариди", "Соев сос", "Чесън", "Джинджифил", "Сусамово масло"],
        instructions: "Загрейте сусамовото масло, запържете чесъна и джинджифила. Добавете скаридите и аспержите, гответе за 5 минути. Добавете соевия сос и сервирайте."
      },
      recipe16: {
        title: "Салата с аспержи",
        description: "Вкусна и здравословна салата с аспержи.",
        ingredients: ["Аспержи", "Маслиново масло", "Лимонов сок", "Сол", "Пипер"],
        instructions: "Смесете всички съставки и сервирайте свежо."
      }
    }
  };
  
  export default recipes;