import React from "react";
import { useTranslation } from "react-i18next";
import logo from "../assets/img/logo.png";
import Navbar from "./Navbar";

const Header = () => {
  const { t } = useTranslation();

  return (
    <header className="header d-flex align-items-center fixed-top">
      <div className="container-fluid container-xl position-relative d-flex align-items-center">
        <a href="/" className="logo d-flex align-items-center me-auto">
          <img src={logo} alt={t("header.logoAlt")} />
          <h1 className="sitename">{t("header.siteName")}</h1>
        </a>
        <Navbar />
        <a className="btn-getstarted" href="#contact">
          {t("header.contact")}
        </a>
      </div>
    </header>
  );
};

export default Header;
