import React from "react";
import HeroSection from "../components/HeroSection";
import AboutSection from "../components/AboutSection";
import OurCommitmentSection from "../components/OurCommitmentSection";
import ContactSection from "../components/ContactSection";

const Home = () => {

  return (
    <>
    <HeroSection />
    <AboutSection />
    <OurCommitmentSection />
    <ContactSection />
    </>
  );
};

export default Home;
