import React from "react";
import { useTranslation } from "react-i18next";

const RecipeCard = ({ recipe }) => {
  const { t } = useTranslation();

  return (
    <div className="recipe-card">
      <h2>{t(recipe.title)}</h2>
      <p>{t(recipe.description)}</p>
      <h3>{t("recipes.ingredients")}</h3>
      <ul>
        {recipe.ingredients.map((ingredient, index) => (
          <li key={index}>{t(ingredient)}</li>
        ))}
      </ul>
      <h3>{t("recipes.instructions")}</h3>
      <p>{t(recipe.instructions)}</p>
    </div>
  );
};

export default RecipeCard;