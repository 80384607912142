import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import varietiesData from "../../data/varieties";
import heroImageBg from "../../assets/img/hero-bg-light.webp";

const CategoryPage = () => {
  const { t } = useTranslation();
  const { categoryId } = useParams();

  if (!categoryId) {
    // Render the landing page for all categories
    return (
      <section id="hero" className="hero section">
        <div className="hero-bg">
          <img src={heroImageBg} alt={t("variety.heroAlt")} />
        </div>
        <div className="container">
          <div className="row gy-4">
            <div
              className="col-lg-12 content"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <p className="variety">{t("variety.categoryTitle")}</p>
              <h3>{t("variety.categoryDescription")}</h3>
              <ul>
                {varietiesData.categories.map((category) => (
                  <li key={category.id}>
                    <a href={`/varieties/${category.id}`}>
                      {t(`categories.${category.id}.name`)}{" "}
                      <i className="bi bi-arrow-right"></i>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }

  // Find the category based on the route parameter
  const category = varietiesData.categories.find((cat) => cat.id === categoryId);

  if (!category) {
    return <p>{t("variety.notFound")}</p>;
  }

  return (
    <section id="hero" className="hero section">
      <div className="hero-bg">
        <img src={heroImageBg} alt={t("variety.heroAlt")} />
      </div>
      <div className="container">
        <div className="row gy-4">
          {/* Category Info Section */}
          <div
            className="col-lg-6 content"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <p className="variety">{t(`categories.${categoryId}.name`)}</p>
            <h3>{t(`categories.${categoryId}.description`)}</h3>
            <ul>
              {category.features.map(
                (feature, index) => (
                  <li key={index}>
                    <i className="bi bi-check-circle"></i> <span>{t(feature)}</span>
                  </li>
                )
              )}
            </ul>
          </div>

          {/* Varieties Section */}
          <div
            className="col-lg-6 about-images"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <h3>{t("variety.varietiesTitle")}</h3>
            <ul>
              {category.varieties.map((varietyId) => {
                return (
                  <li key={varietyId}>
                    <a href={`/varieties/${categoryId}/${varietyId}`}>
                      {t(`varieties.${varietyId}.name`)}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CategoryPage;
