const categories = {
    en: {
        "all-male-hybrids": {
          "name": "All-Male Hybrids",
          "description": "All-male hybrids are designed to maximize spear production by eliminating female plants, which focus energy on seed production. These varieties are perfect for commercial growers seeking high yields and disease resistance.",
          "features": [
            "High-yield hybrids with consistent performance.",
            "Strong resistance to rust and Fusarium wilt.",
            "Ideal for large-scale commercial farming."
          ]
        },
        "purple-varieties": {
          "name": "Purple Varieties",
          "description": "Purple varieties are known for their striking color and sweet flavor, making them a favorite for fresh consumption and gourmet dishes.",
          "features": [
            "Distinctive purple color with a tender texture.",
            "Higher sugar content than green varieties.",
            "Excellent for fresh consumption or culinary use."
          ]
        },
        "open-pollinated": {
          "name": "Open-Pollinated Varieties",
          "description": "Open-pollinated varieties are the traditional choice, offering a balance of productivity and adaptability for growers who prefer natural propagation.",
          "features": [
            "Easy to grow and adaptable to different climates.",
            "Ideal for small-scale farms and gardens.",
            "Reliable production of quality spears."
          ]
        },
        "warm-climates": {
          "name": "Warm-Climate Varieties",
          "description": "Varieties specially bred to thrive in warm and subtropical climates, ensuring productivity even in challenging conditions.",
          "features": [
            "High tolerance to heat and drought.",
            "Ideal for subtropical and tropical regions.",
            "Consistent yields in warmer climates."
          ]
        },
        "disease-resistant": {
          "name": "Disease-Resistant Varieties",
          "description": "Highly disease-resistant varieties that are ideal for regions prone to rust and Fusarium wilt, ensuring healthier crops and reduced losses.",
          "features": [
            "Strong resistance to common asparagus diseases.",
            "Reliable yields in disease-prone areas.",
            "Low maintenance and ideal for commercial farming."
          ]
        },
        "european-varieties": {
          "name": "European Varieties",
          "description": "European varieties are known for their adaptability and unique characteristics, including strong disease resistance and high yields.",
          "features": [
            "Adapted for cooler climates and European markets.",
            "High resistance to diseases and pests.",
            "Consistent spear production and quality."
          ]
        },
        "newer-hybrids": {
          "name": "Newer Hybrids",
          "description": "Newer hybrids represent the latest in asparagus breeding, offering enhanced productivity, adaptability, and disease resistance.",
          "features": [
            "State-of-the-art breeding for modern agriculture.",
            "Highly productive and adaptable to diverse climates.",
            "Strong disease resistance and uniform spear growth."
          ]
        },
        "niche-varieties": {
          "name": "Experimental and Niche Varieties",
          "description": "Experimental and niche varieties cater to specific needs, including unique flavors, colors, and growth characteristics.",
          "features": [
            "Unique characteristics for specialty markets.",
            "Ideal for gourmet or niche farming operations.",
            "Distinctive growth patterns and spear qualities."
          ]
        }
      },
    bg:{
        "all-male-hybrids": {
          "name": "Хибриди само с мъжки растения",
          "description": "Хибридите само с мъжки растения са създадени за максимално производство на стръкове чрез елиминиране на женските растения, които използват енергия за производство на семена. Тези сортове са идеални за търговски производители, които търсят високи добиви и устойчивост на болести.",
          "features": [
            "Високодобивни хибриди с консистентно представяне.",
            "Силна устойчивост на ръжда и фузариум.",
            "Идеални за търговско земеделие в голям мащаб."
          ]
        },
        "purple-varieties": {
          "name": "Лилави сортове",
          "description": "Лилавите сортове са известни със своята поразителна цветова гама и сладък вкус, което ги прави предпочитани за прясна консумация и гурме ястия.",
          "features": [
            "Отличителен лилав цвят с нежна текстура.",
            "По-високо съдържание на захар в сравнение със зелените сортове.",
            "Отлични за прясна консумация или кулинарна употреба."
          ]
        },
        "open-pollinated": {
          "name": "Сортове със свободно опрашване",
          "description": "Сортовете със свободно опрашване са традиционният избор, предлагащ баланс между продуктивност и адаптивност за производители, които предпочитат естественото размножаване.",
          "features": [
            "Лесни за отглеждане и адаптивни към различни климатични условия.",
            "Идеални за малки ферми и градини.",
            "Надеждно производство на качествени стръкове."
          ]
        },
        "warm-climates": {
          "name": "Сортове за топъл климат",
          "description": "Сортове, специално селектирани за успешно отглеждане в топъл и субтропичен климат, гарантирайки продуктивност дори при трудни условия.",
          "features": [
            "Висока толерантност към топлина и засушаване.",
            "Идеални за субтропични и тропични региони.",
            "Постоянни добиви в по-топъл климат."
          ]
        },
        "disease-resistant": {
          "name": "Устойчиви на болести сортове",
          "description": "Високо устойчиви на болести сортове, идеални за региони, склонни към ръжда и фузариум, осигуряващи по-здрави култури и намалени загуби.",
          "features": [
            "Силна устойчивост на често срещани заболявания при аспержите.",
            "Надеждни добиви в райони с висока честота на заболявания.",
            "Лесна поддръжка, идеална за търговско земеделие."
          ]
        },
        "european-varieties": {
          "name": "Европейски сортове",
          "description": "Европейските сортове са известни със своята адаптивност и уникални характеристики, включително висока устойчивост на болести и високи добиви.",
          "features": [
            "Адаптирани за по-хладен климат и европейски пазари.",
            "Висока устойчивост на болести и вредители.",
            "Консистентно производство на стръкове с високо качество."
          ]
        },
        "newer-hybrids": {
          "name": "Нови хибриди",
          "description": "Новите хибриди представляват последните постижения в селекцията на аспержи, предлагайки подобрена продуктивност, адаптивност и устойчивост на болести.",
          "features": [
            "Модерна селекция за съвременното земеделие.",
            "Много продуктивни и адаптивни към разнообразни климатични условия.",
            "Силна устойчивост на болести и равномерно развитие на стръковете."
          ]
        },
        "niche-varieties": {
          "name": "Експериментални и нишови сортове",
          "description": "Експерименталните и нишовите сортове отговарят на специфични нужди, включително уникални вкусове, цветове и характеристики на растеж.",
          "features": [
            "Уникални характеристики за специализирани пазари.",
            "Идеални за гурме или нишово земеделие.",
            "Отличителни модели на растеж и качества на стръковете."
          ]
        }
      }
  };
  
  export default categories;