import React from "react";
import { NavLink } from "react-router-dom";
import menuItems from "../data/navigation"
import MobileNavToggle from "./MobileNavToggle";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const { t } = useTranslation();
  
  const renderSubItems = (subItems) => (
    <ul>
      {subItems.map((subItem, index) => (
        <li className={subItem.subItems ? "dropdown" : ""} key={index}>
          <NavLink to={subItem.to}>
            {t(subItem.label)}
            {subItem.subItems && <i className="bi bi-chevron-down toggle-dropdown"></i>}
          </NavLink>
          {subItem.subItems && renderSubItems(subItem.subItems)}
        </li>
      ))}
    </ul>
  );

  return (
    <nav id="navmenu" className="navmenu">
      { <ul>
        {menuItems.map((item, index) => (
          <li className={item.dropdown ? "dropdown" : "" } key={index}>
             <NavLink
              to={item.to}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              {t(item.label)}
              {item.dropdown && <i className="bi bi-chevron-down toggle-dropdown"></i>}
            </NavLink>
            {item.subItems && renderSubItems(item.subItems)}
          </li>
        ))}
      </ul> }
      
      <MobileNavToggle />
    </nav>
  );
};

export default Navbar;
