import React from "react";
import { useTranslation } from "react-i18next";

const VarietyCard = ({ variety }) => {
  const { t } = useTranslation();

  return (
    <div className="row gy-4">
      {/* Variety Info Section */}
      <div className="col-lg-6 content" data-aos="fade-up" data-aos-delay="100">
        <p className="variety">{t(variety.name)}</p>
        <h3>{t("variety.about", { name: t(variety.name) })}</h3>
        <p className="fst-italic">
          {t(variety.description) || t("variety.defaultDescription")}
        </p>
        <ul>
          {variety.features &&
            variety.features.map((feature, index) => (
              <li key={index}>
                <i className="bi bi-check-circle"></i> <span>{t(feature)}</span>
              </li>
            ))}
        </ul>
      </div>

      {/* Variety Images Section */}
      <div
        className="col-lg-6 about-images"
        data-aos="fade-up"
        data-aos-delay="200"
      >
        <div className="row gy-4">
          {variety.images &&
            variety.images.map((image, index) => (
              <div className="col-lg-6" key={index}>
                <img
                  src={image}
                  className="img-fluid"
                  alt={`${t(variety.name)} ${index + 1}`}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default VarietyCard;
